<template>
    <div class="fix barraNavegacion">
            <ul class="pl-0">
                <li>
                    <div class="cambiarAncho"><div class="flechaAncho" onclick="document.documentElement.classList.toggle('menuCambiado')"><font-awesome-icon icon="angle-left" /></div></div>
                </li>
                <li v-if="sessionStorageUser.rol_id == (1)||(2)">
                    <router-link :to="{name:'dashboard'}"><div id="elemento0" class="elementoMenu menuActivo"><font-awesome-icon icon="home"/><b class="pl-1 desaparece">Inicio</b></div></router-link>
                </li>
                <li v-if="sessionStorageUser.rol_id == (1)||(2)">
                    <router-link :to="{name:'parametros'}"><div id="elemento6" class="elementoMenu menuActivo" :class="{'d-none': sessionStorageUser.rol_id == 4}"><font-awesome-icon icon="wrench"/><b class="pl-2 desaparece">Parámetros</b></div></router-link>
                </li> 
                <li v-if="sessionStorageUser.rol_id == (1)||(2)">
                    <router-link :to="{name:'reportes'}"><div id="elemento1" class="elementoMenu menuActivo" :class="{'d-none': sessionStorageUser.rol_id == 4}"><font-awesome-icon icon="chart-line"/><b class="pl-1 desaparece">Reportes</b></div></router-link>
                </li>
                <li v-if="sessionStorageUser.rol_id == (1)||(2)">
                    <router-link :to="{name:'historial'}"><div id="elemento2" class="elementoMenu menuActivo" :class="{'d-none': sessionStorageUser.rol_id == 4}"><font-awesome-icon icon="history"/><b class="pl-1 desaparece">Historial</b></div></router-link>
                </li>


                <li v-if="sessionStorageUser.rol_id == (1)||(2)" @click="borraSender">
                    <router-link :to="'/chat'"><div id="elemento5" class="elementoMenu bg-primary d-none d-sm-none d-md-block"><font-awesome-icon icon="comment-dots"/><b class="pl-1 desaparece">Inbox</b></div></router-link>
                </li>
                
            </ul>
        </div>
</template>
<style scoped>
.cambiarAncho{
    background-color: #00000000;
    bottom: -10px;
}
</style>
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleLeft, faCommentDots, faChartLine, faHistory, faPaperPlane, faTable, faEye, faExclamationTriangle, faWrench, faViruses,faSlidersH,faRibbon, faFileMedicalAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faAngleLeft, faCommentDots, faChartLine, faHistory, faPaperPlane, faTable, faEye, faExclamationTriangle, faWrench, faViruses,faSlidersH,faRibbon, faFileMedicalAlt)
export default {
    name: 'Menu',
    components: {
        'font-awesome-icon': FontAwesomeIcon
    },
    data() {
        return {
            sessionStorageUser: JSON.parse(sessionStorage.getItem('sesion')),
            ruta: window.location.pathname
        };
    },
    created(){
        document.documentElement.classList.toggle('menuCambiado')
    },
    methods:{
        borraSender(){
            sessionStorage.removeItem('seleccionChat')
            sessionStorage.removeItem('senderid')
            sessionStorage.removeItem('sender_actual')
        }
    },
}
</script>